<template>
  <div>
    <indicator-add-new
      :is-add-new-indicator-sidebar-active.sync="isAddNewIndicatorSidebarActive"
      @refresh-data="() => { refIndicatorList.refreshData() }"
    />
    <list
      ref="refIndicatorList"
      sort-by="indicadorId"
      :refetch-records="fetchIndicators"
      refetch-records-name="indicadores"
      :actions="actions"
      key-field="indicadorId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteIndicator"
      :filters.sync="filters"
    />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import generalData from '@/services/generalData.service'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import IndicatorAddNew from './IndicatorAddNew.vue'

export default {
  components: {
    IndicatorAddNew,
    List,

  },
  setup() {
    /* Refs */
    const refIndicatorList = ref(null)
    const isAddNewIndicatorSidebarActive = ref(false)
    /* Services */
    const filters = ref([])
    const { fetchIndicators, fetchIndicator, deleteIndicator } = generalData()
    const tableColumns = [
      {
        label: 'Clave',
        key: 'clave',
      },
      {
        label: 'Nombre',
        key: 'nombre',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'GeneralDatos',
        routeName: 'apps-indicator-edit',
        params: ['indicadorId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'GeneralDatos',
        params: ['indicadorId'],
        // disabledCondition: item => item.estadoCivilId === 3,
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: 'Nueva accion mejora',
        aclAction: 'create',
        click: () => { isAddNewIndicatorSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    return {
      // Ref
      refIndicatorList,
      tableColumns,
      isAddNewIndicatorSidebarActive,
      tableActions,
      actions,
      filters,
      // API Calls
      fetchIndicators,
      fetchIndicator,
      deleteIndicator,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
